import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section" style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/logo.png" alt="Doora Logo" style={{ maxWidth: '180px' }} />
        </div>
        
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>Email: agent@doora.co.uk</p>
          <p>London, United Kingdom</p>
        </div>
        
        <div className="footer-section">
          <h4>Stay Connected</h4>
          <div style={{ display: 'flex', gap: '15px', margin: '10px 0' }}>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path></svg>
            </a> */}
            <a href="https://www.linkedin.com/company/doora-ai" target="_blank" rel="noopener noreferrer" aria-label="Doora LinkedIn Page">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2025 UpswingAI Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 
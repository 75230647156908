import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';

const NewFeatures = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  const features = [
    {
      title: "Automatic Call Handling",
      description: "Books, reschedules, and cancels property viewings and valuations automatically.",
      icon: "/phone-icon.svg"
    },
    {
      title: "Smart Follow-Ups",
      description: "Sends reminders via text and follows up with calls if needed.",
      icon: "/bell-icon.svg"
    },
    {
      title: "Seamless Integrations",
      description: "Connects with Google Workspace, Microsoft Office, and more.",
      icon: "/integration-icon.svg"
    },
    {
      title: "WhatsApp Chat Included",
      description: "AI-powered text assistance for instant customer interactions over message.",
      icon: "/chat-icon.svg"
    },
    {
      title: "Full Observability",
      description: "Access call transcripts, text logs, and in-depth analytics via the dashboard.",
      icon: "/analytics-icon.svg"
    },
    {
      title: "Knowledge-Driven",
      description: "Equipped with property details to answer queries with accuracy.",
      icon: "/knowledge-icon.svg"
    }
  ];

  return (
    <section id="features-section" className={`new-features fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <div className="features-header">
        <h4 className="features-label">FEATURES</h4>
        {/*<div className="features-title">
          <h2>Powerful AI assistant.</h2>
          <h2>Effortless property management.</h2>
        </div>*/}
      </div>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-item">
            <div className="feature-icon">
              <img src={feature.icon} alt={feature.title} />
            </div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewFeatures; 
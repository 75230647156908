import React, { useState, useEffect } from "react";
import { SplineScene, Spotlight, Card } from "../components/ui";

const HeroWithRobot = () => {
  const messages = [
    "Smarter Property Conversations, Powered by AI",
    "Empowering Estate Agencies with next-gen AI",
    "Revolutionising Property Conversations with AI",
    "The AI Agent Built for Estate Agents",
    "The 24/7 AI Assistant for Property Enquiries",
  ];

  const [currentMessage, setCurrentMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const typingSpeed = 100; // Speed of typing effect in ms
  const deleteSpeed = 50; // Speed of deleting effect in ms
  const pauseTime = 1500; // Time before deleting text

  useEffect(() => {
    let timeout;
    const currentText = messages[messageIndex];

    // If we're waiting, don't do anything yet
    if (isWaiting) {
      timeout = setTimeout(() => {
        setIsWaiting(false);
        setIsDeleting(true);
      }, pauseTime);
      return () => clearTimeout(timeout);
    }

    // If we're typing (not deleting)
    if (!isDeleting) {
      if (charIndex < currentText.length) {
        timeout = setTimeout(() => {
          setCurrentMessage(currentText.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        }, typingSpeed);
      } else {
        // Finished typing, start waiting before deletion
        setIsWaiting(true);
      }
      return () => clearTimeout(timeout);
    }
    
    // If we're deleting
    if (charIndex > 0) {
      timeout = setTimeout(() => {
        setCurrentMessage(currentText.substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
      }, deleteSpeed);
    } else {
      // Finished deleting, move to next message
      setIsDeleting(false);
      setMessageIndex((messageIndex + 1) % messages.length);
    }
    
    return () => clearTimeout(timeout);
  }, [charIndex, messageIndex, isDeleting, isWaiting, messages]);

  return (
    <div className="hero-container">
      {/* Main Hero Section with Robot */}
      <div className="hero-card">
        <Spotlight className="spotlight-position" />
        <div className="hero-background">
          <img src="/houses-final.png" alt="Background" className="hero-background-image" />
        </div>
        
        <div className="hero-content-container">
          {/* Left content */}
          <div className="hero-left-content">
            <div className="hero-message">
              {currentMessage}
              <span className="cursor" aria-hidden="true"></span> {/* Cursor effect */}
            </div>
            <h1 className="hero-title">
              <div className="no-wrap">Meet Doora, the</div>
              <svg viewBox="0 0 500 62" className="inline-svg" style={{ maxWidth: '100%', height: 'auto', marginBottom: '-16px' }}>
                <text x="0" y="50" fill="#2565BD" fontSize="52px" fontWeight="bold" className="svg-text">AI Voice Agent</text>
              </svg>
              <div style={{ margin: '0', padding: '0', lineHeight: '1.5' }}>designed for</div>
              <svg viewBox="0 0 700 65" className="inline-svg" style={{ maxWidth: '100%', height: 'auto', marginTop: '-8px' }}>
                <text x="0" y="50" fill="#3E93D5" fontSize="56px" fontWeight="bold" className="svg-text">Estate Agencies</text>
              </svg>
            </h1>
            <p className="hero-description">
              Your own virtual agent that answers 100 percent of phone calls, 24 hours a day, 365 days a year. Doora can organise, cancel, and reschedule property valuations & viewings on your behalf - plus more!
            </p>
            <div className="hero-buttons-container">
              <button 
                className="demo-hero-button"
                onClick={() => {
                  const demoSection = document.getElementById('demo-section');
                  if (demoSection) {
                    demoSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Book a Demo
              </button>
            </div>
          </div>

          {/* Right content with 3D Robot */}
          <div className="hero-right-content">
            <SplineScene 
              scene="https://prod.spline.design/rtvMQ1fIWzlVY1nB/scene.splinecode"
              // scene="https://prod.spline.design/W1qHKbuhqD9ctJux/scene.splinecode"
              // scene="https://prod.spline.design/W1qHKbuhqD9ctJux/scene.splinecode"
              className="spline-scene"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroWithRobot; 
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
// import Hero from './components/Hero';
import HeroWithRobot from './components/HeroWithRobot';
import DoraFeatures from './components/Features';
import NewFeatures from './components/NewFeatures';
import DemoForm from './components/DemoForm';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Video from './components/Video'
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="app-container">
        <HeroWithRobot />
        <Video />
        <DoraFeatures />
        <NewFeatures />
        <DemoForm />
        <FAQ />
        <Footer />
      </div>
      <ScrollToTop />
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
          style: {
            background: '#f0fdf4',
            color: '#166534',
            fontSize: '1.1rem',
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          },
          success: {
            iconTheme: {
              primary: '#166534',
              secondary: '#f0fdf4',
            },
          },
          error: {
            style: {
              background: '#fef2f2',
              color: '#991b1b',
            },
            iconTheme: {
              primary: '#991b1b',
              secondary: '#fef2f2',
            },
          },
        }}
      />
    </div>
  );
}

export default App;

import React, { useRef, useState } from 'react';
import { useInView } from '../hooks/useInView';

const Video = () => {
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const isInView = useInView(sectionRef);
  const [showThumbnail, setShowThumbnail] = useState(true);

  const handlePlayVideo = () => {
    setShowThumbnail(false);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className={`video-container fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <div className="video-header">
        <h2>
          Say hello to<span className="gradient-text">Doora</span>...<br></br>
        </h2>
      </div>
      <div className="video-section">
        {/* Video player with thumbnail overlay */}
        <div className="video-player-container">
          {showThumbnail ? (
            <div className="video-thumbnail" onClick={handlePlayVideo}>
              <img src="/vid_thumbnail.png" alt="Doora video thumbnail" />
              <div className="play-button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="64" height="64">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </div>
            </div>
          ) : null}
          <video 
            ref={videoRef} 
            controls={!showThumbnail} 
            preload="none"
            playsInline
          >
            <source src="/doora_vid_3.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Video;
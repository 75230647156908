import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const scrollToFeatures = () => {
    const featuresSection = document.getElementById('features-section');
    if (featuresSection) {
      const offset = 100; // Adjust this value to fine-tune the scroll position
      const elementPosition = featuresSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMenuOpen(false);
  };

  const scrollToFAQ = () => {
    const faqSection = document.getElementById('faq-section');
    if (faqSection) {
      const offset = 100; // Adjust this value to fix the scroll position
      const elementPosition = faqSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMenuOpen(false);
  };

  const scrollToDemo = () => {
    const demoSection = document.getElementById('demo-section');
    if (demoSection) {
      demoSection.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  const navigateToDashboard = () => {
    window.location.href = "https://dashboard.doora.co.uk";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src="logo.png" alt="Logo"/>
      </div>
      
      {isMobile ? (
        <>
          <button 
            className="mobile-menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="4" y1="8" x2="20" y2="8"></line>
                <line x1="4" y1="16" x2="20" y2="16"></line>
              </svg>
            )}
          </button>
          
          {isMenuOpen && (
            <div className="menu-backdrop" onClick={closeMenu}></div>
          )}
          
          <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <button className="mobile-menu-item" onClick={scrollToFeatures}>Features</button>
            <button className="mobile-menu-item" onClick={scrollToFAQ}>FAQs</button>
            <button className="mobile-menu-item" onClick={scrollToDemo}>Book a Demo</button>
            <button className="mobile-menu-item dashboard-button" onClick={navigateToDashboard}>
              Dashboard
            </button>
          </div>
        </>
      ) : (
        <div className="navbar-buttons">
          <button className="demo-button" onClick={scrollToFeatures}>Features</button>
          <button className="demo-button" onClick={scrollToFAQ}>FAQs</button>
          <button className="demo-button" onClick={scrollToDemo}>Book a Demo</button>
          <button
            className="demo-button dashboard-button"
            onClick={navigateToDashboard}
          >
            Dashboard
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar; 